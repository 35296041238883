import React,{useEffect} from 'react'
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName,findRouteByRouteName } from '../../util/routes';
import {  parse } from '../../util/urlHelpers';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { useDispatch } from 'react-redux';

export const AdobePageComponent = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.search && window.location.search.search('state=')) {
      const params = parse(window.location.search);
      if(params.state){
      const routes = routeConfiguration();
      const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

      dispatch(
        OrderPage.setInitialValues({
          initialMessageFailedToTransaction: params.state,
          adobeCode:params.code,
        })
      );
      const TransactionPage = pathByRouteName('OrderDetailsPage', routes, {
        id: params.state
      });
      props.history.push(TransactionPage);
    }
    }
  }, [])

    return (
        <div>
            <h1>Loading......</h1>
        </div>
    )
}

const AdobePage = compose(
  withViewport,
  withRouter,
  injectIntl
)(AdobePageComponent);

export default AdobePage;
